<template>
  <b-modal
    ref="clientExpenseRequest"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="Clients"
    size="lg"
    @hidden="closeModal()"
    hide-footer
  >
    <b-row class="client-info">
      <!-- Client -->
      <b-col cols="6" class="p-0">
        <span class="mr-1 mb-1"
          >{{
            ["CHECK", "E-CHECK"].includes(expenseDepartment.method)
              ? "Pay to"
              : "Vendor"
          }}
        </span>
        <div
          class="mr-1"
          :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
        >
          <p>
            {{ expenseDepartment.vendor }}
          </p>
        </div>
      </b-col>
      <b-col cols="6" class="p-0">
        <span class="ml-1 mb-1">
          {{
            ["CHECK", "E-CHECK"].includes(expenseDepartment.method)
              ? "Memo"
              : "Concept"
          }}
        </span>
        <div
          class="ml-1"
          :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
        >
          <p>
            {{ expenseDepartment.concept }}
          </p>
        </div>
      </b-col>
    </b-row>
    <b-table
      id="detailsExpenses"
      ref="detailsExpenses"
      no-border-collapse
      class="position-relative table-new-customization"
      :fields="fields"
      show-empty
      no-provider-filtering
      sticky-header="50vh"
      primary-key="id"
      responsive="sm"
      :items="itemsClientsExpense"
      :busy.sync="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(client)="{ item }">
        <div>
          <router-link
            v-if="!notClickeable && item.type_client == 'CLIENT'"
            class
            :to="
              isCeo
                ? routerDashboardRedirectioner(
                    item.program_id,
                    item.client_account_id
                  )
                : {
                    name: getRouterName(item)[0],
                    params: getRouterName(item)[1],
                  }
            "
            target="_blank"
            >{{ item.client }}
          </router-link>
          <router-link
            v-else-if="!notClickeable && item.type_client == 'LEAD'"
            class
            :to="
              isCeo
                ? routerDashboardLeadRedirectioner(2, item.lead_id)
                : {
                    name: getRouterName(item)[0],
                    params: getRouterName(item)[1],
                  }
            "
            target="_blank"
            >{{ item.client }}
          </router-link>
          <span v-else>{{ item.client }}</span>
          <div v-if="item.type_client == 'CLIENT'">
            <status-account :account="{ ...item, status: item.status }" />
            <span>{{ item.account }}</span>
          </div>
          <div v-else>
            <span class="d-block">ID: {{ item.lead_id }}</span>
            <span class="d-block"
              >Start date: {{ item.lead_start_date | myGlobal }}</span
            >
          </div>
        </div>
      </template>
      <template #cell(type_client)="{ item }">
        <b-badge
          :variant="item.type_client == 'CLIENT' ? 'primary' : 'warning'"
          >{{ item.type_client }}</b-badge
        >
      </template>
      <template #cell(amount)="{ item }">
        {{ item.amount | formatMoney }}
      </template>
    </b-table>
  </b-modal>
</template>
<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import RoutesProgram from "@/views/commons/components/ring-central/call-log/data/RoutesName.js";
import { mapActions } from "vuex";

export default {
  name: "ClientExpenseRequest",
  props: {
    expenseDepartment: {
      type: Object,
      required: true,
    },
    notClickeable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    StatusAccount,
  },
  data() {
    return {
      fields: [
        {
          key: "client",
          label: "Client",
          visible: true,
          thClass: "text-left",
          tdClass: "text-left",
        },
        {
          key: "type_client",
          label: "Type",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "amount",
          label: "Amount",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      isBusy: false,
      routesPrograms: [...RoutesProgram],
      itemsClientsExpense: [],
    };
  },
  computed: {
    title() {
      return `${
        this.expenseDepartment.method == "CHECK" ? "Pay to" : "Vendor"
      }: ${this.expenseDepartment.vendor} | ${
        this.expenseDepartment.method == "CHECK" ? "Memo" : "Concept"
      }: ${this.expenseDepartment.concept}`;
    },
  },
  async created() {
    await this.A_GET_PROGRAMS();
    await this.loadClientsExpense();
    this.toggleModal("clientExpenseRequest");
  },
  methods: {
    ...mapActions({
      A_GET_PROGRAMS: "TransactionStatusStore/A_GET_PROGRAMS",
    }),
    async loadClientsExpense() {
      try {
        this.addPreloader();
        const data = await DashboardServive.getClientByExpenseDepartmentId({
          expense_department_id: this.expenseDepartment.id,
        });
        this.itemsClientsExpense = data;
      } catch (ex) {
        console.log(ex);
      } finally {
        this.removePreloader();
      }
    },
    closeModal() {
      this.$emit("close");
    },
    getRouterName(item) {
      let route = ["lead-show", { id: item.lead_id }];

      if (this.moduleId === 18) {
        return ["quality-lead-show", { id: item.lead_id }];
      }

      for (const element of this.routesPrograms) {
        if (element.module.includes(item.module_id)) {
          if (item.client_account_id) {
            route = [
              element.dashboardCLient,
              { [element.clientParam]: item.client_account_id },
            ];
          } else {
            route = [element.dashboardLeads, { id: item.lead_id }];
          }
        }
      }
      return route;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
