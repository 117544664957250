<template>
  <div>
    <b-modal
      size="lg"
      v-model="showModal"
      body-class="p-0"
      no-close-on-backdrop
      @hidden="$emit('close')"
      ref="modal-register-shipment"
      id="modal-1"
      title="SEND TO COURIER"
      :hide-footer="onlyRead"
    >
      <div class="m-2">
        <ValidationObserver ref="observer">
          <div class="form-row">
            <div class="form-group mb-0 col-md-6" v-if="!onlyRead">
              <b-form-group
                label="Type of Shipping"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-radio-group
                  id="btn-radios-2"
                  v-model="selectedShipping"
                  :options="options"
                  :aria-describedby="ariaDescribedby"
                  button-variant="outline-primary"
                  size="md"
                  name="radio-btn-outline"
                  buttons
                  class="w-100"
                ></b-form-radio-group>
              </b-form-group>
            </div>
            <div class="form-group mb-0 col-md-6">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="check_number"
              >
                <b-form-group label="Check Number">
                  <b-form-input
                    v-model="checkNumber"
                    type="text"
                    class="form-control"
                    maxlength="30"
                    :disabled="onlyRead"
                  >
                  </b-form-input>
                  <span v-if="errors[0]" class="text-danger">
                    Check number {{ errors[0] }}
                  </span>
                </b-form-group>
              </ValidationProvider>
            </div>

            <div class="form-group mb-0 col-md-6">
              <ValidationProvider
                name="tracking_date"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  id="input-group-1"
                  label="Date"
                  label-for="input-1"
                >
                  <b-form-datepicker
                    v-model="trackingDate"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    :class="errors[0] ? 'is-invalid' : ''"
                    class="form-control"
                    style="margin-top: 8px"
                    :disabled="onlyRead"
                  ></b-form-datepicker>
                  <span v-if="errors[0]" class="text-danger">
                    Date {{ errors[0] }}
                  </span>
                </b-form-group>
              </ValidationProvider>
            </div>
            <!-- Overnight -->
            <template v-if="selectedShipping == 2">
              <div class="form-group mb-0 col-md-6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="tracking_number"
                >
                  <b-form-group label="Tracking Number">
                    <b-form-input
                      v-model="trackingNumber"
                      type="text"
                      class="form-control text-uppercase"
                      maxlength="30"
                      :disabled="onlyRead"
                    >
                    </b-form-input>
                    <span v-if="errors[0]" class="text-danger">
                      Tracking number {{ errors[0] }}
                    </span>
                  </b-form-group>
                </ValidationProvider>
              </div>

              <div class="form-group mb-0 col-md-6">
                <!-- <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="evidence"
                > -->
                  <b-form-group label="Evidence">
                    <b-input-group>
                      <template #append>
                        <div
                          class="d-flex justify-content-center align-items-center"
                          v-if="fileToUpload"
                        >
                          <feather-icon
                            icon="FileIcon"
                            class="cursor-pointer text-primary ml-1"
                            size="20"
                            v-b-tooltip.hover.top="'Preview'"
                            @click="previewEvidence(fileToUpload)"
                          />
                          <feather-icon
                            icon="TrashIcon"
                            class="cursor-pointer text-danger ml-1"
                            size="20"
                            v-b-tooltip.hover.top="'Remove'"
                            @click="removeFile"
                          />
                        </div>
                      </template>
                      <b-form-file
                        v-if="!onlyRead"
                        v-model="fileToUpload"

                        placeholder="Choose a file"
                        @input="fileFill"
                      />
                      <a
                        v-else
                        :href="dataRegister.route_evidence"
                        target="_blank"
                      >
                        <feather-icon
                          class="cursor-pointer text-primary"
                          style="width: 24px; height: 36px"
                          icon="FileIcon"
                        />
                      </a>
                      <!-- <span v-if="errors[0]" class="text-danger">
                        File {{ errors[0] }}
                      </span> -->
                    </b-input-group>
                  </b-form-group>
                <!-- </ValidationProvider> -->
              </div>
            </template>
          </div>
        </ValidationObserver>
      </div>
      <template #modal-footer>
        <div>
          <b-button @click="$emit('close')">Cancel</b-button>
        </div>
        <div>
          <b-button @click="saveTracking()" variant="primary">Done</b-button>
        </div>
      </template>
      <image-viewer
        v-if="previewUrl && !isPdf"
        :objectImages="[previewUrl]"
        :route="previewUrl"
        :showBottom="false"
        @hidden="clearPreview"
        style="z-index: 1002 !important"
      />
    </b-modal>
    <preview-pdf
      v-if="previewUrl && isPdf"
      :pdf-url="previewUrl"
      :pdf-name="fileName"
      @close="clearPreview"
    />
  </div>
</template>
<script>
import CorrespondenceServices from "@/views/correspondence/services/correspondence.service.js";
import moment from "moment";
import { mapGetters } from "vuex";
import PreviewPdf from "@/views/debt-solution/views/court-info/components/PreviewPdf.vue";
import ImageViewer from "@/views/commons/components/paragon-soft/components/ImageViewer.vue";

export default {
  props: {
    dataRegister: {
      type: Object,
    },
    onlyRead: {
      type: Boolean,
    },
  },
  components: {
    PreviewPdf,
    ImageViewer,
  },
  data() {
    return {
      showModal: false,
      trackingNumber: null,
      trackingDate: null,
      checkNumber: null,
      namedoc: null,
      size: null,
      file: null,
      fileToUpload: null,
      checkNumberCpy: null,
      previewUrl: null,
      fileName: null,
      fileType: null,
      options: [
        { text: "REGULAR", value: 1 },
        { text: "OVERNIGHT", value: 2 },
      ],
      selectedShipping: 1,
    };
  },
  created() {
    this.checkNumber = this.dataRegister.check_number;
    this.checkNumberCpy = this.dataRegister.check_number;
    this.trackingDate =
      this.dataRegister.courier_date || moment().format("YYYY-MM-DD");
    this.trackingNumber = this.dataRegister.tracking_number || null;
    this.showModal = true;
    if(this.onlyRead) {
      this.selectedShipping = this.getTypeShipping();
    } 
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isPdf() {
      return this.fileType === "application/pdf";
    },
  },
  methods: {
    getTypeShipping() {
      if(this.dataRegister.type_shipping == 'regular') return 1;
      else if(this.dataRegister.type_shipping == 'overnight') return 2;
      else return "UNKNOWN";
    },
    previewEvidence(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewUrl = e.target.result;
          this.fileName = file.name;
          this.fileType = file.type;
        };
        reader.readAsDataURL(file);
      }
    },
    removeFile() {
      this.fileToUpload = null;
      this.previewUrl = null;
    },
    clearPreview() {
      this.previewUrl = null;
      this.fileName = null;
    },
    async saveTracking() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;

      const confirm = await this.showConfirmSwal(
        "Are you sure you confirm the sendig to courier?"
      );
      if (!confirm.isConfirmed) return;

      try {
        this.addPreloader();
        const params = {
          tracking_number: this.trackingNumber,
          check_number: this.checkNumber,
          collect_date: this.trackingDate,
          namedoc: this.namedoc,
          size: this.size,
          file: this.file,
          check_id: this.dataRegister.check_id,
          user_id: this.currentUser.user_id,
          check_number_changed: this.checkNumberCpy != this.checkNumber,
          old_check_number: this.checkNumberCpy,
          type_shipping: this.selectedShipping,
        };
        const { data } = await CorrespondenceServices.saveTracking(params);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Successfully process"
          );
          this.$emit("refresh");
          this.$emit("close");
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Oop!",
            "AlertOctagonIcon",
            "Failed process"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    closeModal() {
      this.showModal = false;
    },
    async fileFill() {
      this.namedoc = this.fileToUpload.name;
      this.size = this.fileToUpload.size;
      const reader = new FileReader();
      reader.readAsDataURL(this.fileToUpload);
      reader.onload = async (file) => {
        this.file = file.target.result;
      };
    },
  },
};
</script>
