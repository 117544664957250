var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-2"},[_c('filter-slot',{staticClass:"filter-slot-new-customization",attrs:{"filter":[],"total-rows":_vm.FiltersForSlot.totalRows,"filter-principal":{},"paginate":_vm.FiltersForSlot.paginate,"start-page":_vm.FiltersForSlot.startPage,"to-page":_vm.FiltersForSlot.toPage,"noVisiblePrincipalFilter":true},on:{"reload":function($event){return _vm.$refs['checksTable'].refresh()}}},[_c('b-table',{ref:"checksTable",staticClass:"blue-scrollbar position-relative table-new-customization",attrs:{"slot":"table","items":_vm.getChecks,"fields":_vm.visibleFields,"busy":_vm.isBusy,"primary-key":"id","responsive":"md","sticky-header":"50vh","show-empty":"","no-provider-filtering":"","per-page":_vm.FiltersForSlot.paginate.perPage,"current-page":_vm.FiltersForSlot.paginate.currentPage,"table-class":"text-nowrap"},on:{"update:busy":function($event){_vm.isBusy=$event}},slot:"table",scopedSlots:_vm._u([{key:"cell(module)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(item.module_name),expression:"item.module_name",modifiers:{"hover":true,"left":true}}],attrs:{"src":("/assets/" + (item.icon_module)),"alt":"Logo","height":"30","width":"30"}})])]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{staticClass:"px-1",class:item.type == 'CLIENT' ? 'clickable' : '',attrs:{"variant":item.type == 'CLIENT' ? 'primary' : 'info'},on:{"click":function($event){item.type == 'CLIENT' ? _vm.openModalClientExpense(item) : false}}},[_vm._v(" "+_vm._s(item.type)+" "+_vm._s(item.total_clients > 0 ? ("(" + (item.total_clients) + ")") : null)+" ")])]}},{key:"cell(type_shipping)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{staticClass:"text-uppercase px-1",attrs:{"variant":value == 'regular' ? 'info' : 'warning'}},[_vm._v(_vm._s(value))])]}},{key:"cell(check_number)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.check_number)+" ")])]}},{key:"cell(check)",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.route_pdf,"target":"_blank"}},[_c('amg-icon',{staticClass:"cursor-pointer text-danger",attrs:{"icon":"FilePdfIcon","size":"18"}})],1)]}},{key:"cell(check_details)",fn:function(ref){
var item = ref.item;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('strong',[_vm._v("Check #")])]),_c('b-col',[_vm._v(_vm._s(item.check_number))]),_c('div',{staticClass:"w-100"}),_c('b-col',{attrs:{"cols":"2"}},[_c('strong',[_vm._v("Pay to")])]),_c('b-col',[_vm._v(_vm._s(item.vendor))]),_c('div',{staticClass:"w-100"}),_c('b-col',{attrs:{"cols":"2"}},[_c('strong',[_vm._v("Memo")])]),_c('b-col',[_vm._v(_vm._s(item.memo))]),_c('div',{staticClass:"w-100"}),_c('b-col',{attrs:{"cols":"2"}},[_c('strong',[_vm._v("Address")])]),_c('b-col',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatAddress(item))}})]),_c('div',{staticClass:"w-100"}),_c('b-col',{attrs:{"cols":"2"}},[_c('strong',[_vm._v("Check")])]),_c('b-col',[_c('a',{attrs:{"href":item.route_pdf,"target":"_blank"}},[_c('amg-icon',{staticClass:"cursor-pointer text-danger",attrs:{"icon":"FilePdfIcon","size":"18"}})],1)]),_c('div',{staticClass:"w-100"}),(item.route_attach)?_c('b-col',{attrs:{"cols":"2"}},[_c('strong',[_vm._v("File")])]):_vm._e(),(item.route_attach)?_c('b-col',[_c('a',{attrs:{"href":item.route_attach,"target":"_blank"}},[_c('amg-icon',{staticClass:"cursor-pointer text-danger",attrs:{"icon":"FilePdfIcon","size":"18"}})],1)]):_vm._e()],1)]}},{key:"cell(amount)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-success"},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(item.amount))+" ")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('SEND TO COURIER'),expression:"'SEND TO COURIER'",modifiers:{"hover":true,"bottom":true}}],staticClass:"cursor-pointer mr-1 text-success",attrs:{"icon":"SendIcon","size":"18"},on:{"click":function($event){return _vm.trackingCheckModal(item, false)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('CANCEL SENDING'),expression:"'CANCEL SENDING'",modifiers:{"hover":true,"bottom":true}}],staticClass:"cursor-pointer mr-1 text-danger",attrs:{"icon":"XIcon","size":"18"},on:{"click":function($event){return _vm.observationModal(item)}}})]}},{key:"cell(to_correspondence)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(item.sent_to_correspondence_by))]),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm._f("myGlobalDay")(item.sent_to_correspondence_at))+" ")])]}},{key:"cell(to_courier)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(item.sent_to_courier_by))]),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm._f("myGlobalDay")(item.sent_to_courier_at))+" ")]),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('See details'),expression:"'See details'",modifiers:{"hover":true,"top":true}}],staticClass:"text-info cursor-pointer",staticStyle:{"margin-left":"3px"},attrs:{"icon":"InfoIcon","variant":"info"},on:{"click":function($event){return _vm.trackingCheckModal(item, true)}}})]}},{key:"cell(cancelled_by)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(item.cancelled_by))]),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm._f("myGlobalDay")(item.cancelled_at)))]),(item.observation)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(item.observation),expression:"item.observation",modifiers:{"hover":true,"top":true}}],staticClass:"text-info",staticStyle:{"margin-left":"3px"},attrs:{"icon":"InfoIcon","variant":"info"}}):_vm._e()]}}])})],1),(_vm.showObservationsModal)?_c('ObservationsModal',{attrs:{"expenseData":_vm.selectedItem},on:{"close":function($event){_vm.showObservationsModal = false},"save":_vm.saveRejectedCheck}}):_vm._e(),(_vm.showTrackingCheckModal)?_c('tracking-check',{attrs:{"dataRegister":_vm.selectedItem,"onlyRead":_vm.onlyRead},on:{"refresh":function($event){return _vm.refreshTable()},"close":function($event){_vm.showTrackingCheckModal = false}}}):_vm._e(),(_vm.expenseDepartment)?_c('client-expense-request',{attrs:{"expenseDepartment":_vm.expenseDepartment,"notClickeable":true},on:{"close":function($event){_vm.expenseDepartment = null}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }