export default [
    {
      module: [2], // "bussiness"
      dashboardLeads: "lead-show", // params :id (lead id)
      dashboardCLient: "lead-show", // params :id (lead id)
      clientParam: "id",
    },
    {
      module: [3], // "bussiness"
      dashboardLeads: "bussiness-lead-show",
      dashboardCLient: "bussiness-dashboard-clients",
      clientParam: "idClient",
    },
    {
      module: [4], // "administration"
      dashboardLeads: "administration-lead-show",
      dashboardCLient: "administration-dashboard",
      clientParam: "idClient",
    },
    {
      module: [5], // "debtsolution"
      dashboardLeads: "debtsolution-lead-show",
      dashboardCLient: "debt-solution-dashboard",
      clientParam: "idClient",
    },
    {
      module: [6], // "creditexperts"
      dashboardLeads: "creditexperts-lead-show",
      dashboardCLient: "credit-experts-dashboard",
      clientParam: "idClient",
    },
    {
      module: [7], // "boostcredit"
      dashboardLeads: "boostcredit-lead-show",
      dashboardCLient: "boost-credit-dashboard",
      clientParam: "idClient",
    },
    {
      module: [8], // "taxresearch"
      dashboardLeads: "tax-research-lead-show",
      dashboardCLient: "tax-research-client-dashboard",
      clientParam: "idClient",
    },
    {
      module: [10], // "courtinfo",
      dashboardLeads: "claimdepartment-lead-show",
      dashboardCLient: "claim-department-dashboard",
      clientParam: "idClient",
    },
    {
      module: [11], // "specialists",
      dashboardLeads: "specialist-lead-show",
      dashboardCLient: "specialists-client-dashboard",
      clientParam: "idClient",
    },
    {
      module: [12], // "paragon",
      dashboardLeads: "ti-lead-show",
      dashboardCLient: "paragon-dashboard",
      clientParam: "idClient",
    },
    {
      module: [14], // "keybook",
      dashboardLeads: "bookeeping-lead-show",
      dashboardCLient: "bookeeping-dashboard",
      clientParam: "idClient",
    },
    {
      module: [15], // "metamedia",
      dashboardLeads: "sn-dashboard-new-lead",
      dashboardCLient: "sn-dashboard-new-lead",
      clientParam: "id",
    },
    {
      module: [18], // "quality",
      dashboardLeads: "connection-lead-show",
      dashboardCLient: "quality-client-dashboard",
      clientParam: "idClient",
    },
    {
      module: [20], // "connection",
      dashboardLeads: "connection-lead-show",
      dashboardCLient: "connection-clients-account",
      clientParam: "idClient",
    },
    {
      module: [22], // "customservice",
      dashboardLeads: "customerservice-lead-show",
      dashboardCLient: "ce-customer-service-dashboard",
      clientParam: "idClient",
    },
     {
      module: [23], // "financial",
      dashboardLeads: "financial-leads-lead-show", // params :id (lead id)
      dashboardCLient: "ce-customer-service-dashboard", // pending
      clientParam: "idClient",
    },
    {
      module: [25], // "specialistdigital",
      dashboardCLient: "specialists-client-dashboard",
      clientParam: "idClient",
    },
    {
      module: [26], // "sales",
      dashboardLeads: "sales-lead-show", // params :id (lead id)
      dashboardCLient: "sales-lead-show",
      clientParam: "id",
    },
    {
      module: [28], // "specialists - analyst department",
      dashboardLeads: "specialist-lead-show",
      dashboardCLient: "specialists-client-dashboard",
      clientParam: "idClient",
    },
    {
      module: [29], // "specialists - financial approval",
      dashboardLeads: "specialist-lead-show",
      dashboardCLient: "specialists-client-dashboard-fa",
      clientParam: "idClient",
    },
  ]
