<template>
  <b-modal
    v-model="showModal"
    title="Observations"
    @hidden="closeModal"
    modal-class="custom-modal-amg"
    size="sm"
    hide-footer
    hide-header
  >
    <div class="text-center mt-1" style="font-size: 1.2rem; font-weight: 600">
      Enter observation (optional)
    </div>
    <div class="text-left mt-1" v-if="expenseData">
      <span class="d-block">
        <span>
          <strong>{{ getPayableLabel(expenseData.method) }}: </strong>
        </span>
        {{ expenseData.vendor }}
      </span>
      <span class="d-block">
        <span>
          <strong>{{ getDescriptionLabel(expenseData.method) }}: </strong>
        </span>
        {{ expenseData.concept }}
      </span>
      <span class="d-block" v-if="expenseData.method == 'CHECK'">
        <span>
          <strong>Address: </strong>
        </span>
        {{
          expenseData.route
            ? `${expenseData.street_number || ""} ${expenseData.route || ""} ${
                expenseData.city || ""
              }, ${expenseData.state_slug || ""} ${expenseData.postal_code || ""}`
            : `${expenseData.address || ""} ${expenseData.city || ""}, ${
                expenseData.state_slug_2 || ""
              } ${expenseData.postal_code || ""}`
        }}
      </span>
      <span class="d-block" v-if="expenseData.method == 'CREDIT_CARD'">
        <span>
          <strong>Date: </strong>
        </span>
        {{ expenseData.expense_date | myGlobal }}
      </span>
      <span class="d-block" v-else>
        <span>
          <strong>Date: </strong>
        </span>
        {{ expenseData.payment_date | myGlobal }}
      </span>
      <span class="d-block">
        <span>
          <strong>Department: </strong>
        </span>
        <img
          v-b-tooltip.hover.left="expenseData.module_name"
          :src="`/assets/${expenseData.icons}`"
          alt="Logo"
          height="20"
          width="20"
        />
      </span>
    </div>
    <div class="p-2">
      <b-textarea autofocus v-model="observation" class="memo-class" no-resize />
    </div>
    <div class="d-flex justify-content-center" style="gap: 10px">
      <b-button @click="closeModal">Cancel</b-button>
      <b-button variant="danger" @click="$emit('save', observation)"
        >{{ getDangerButtonText(expenseData.method) }}</b-button
      >
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ObservationsModal",
  props: {
    expenseData: {
      type: Object,
      required: false,
    },
  },
  components: {},
  data() {
    return {
      observation: null,
      showModal: true,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    getPayableLabel(method) {
      switch (method) {
        case "CREDIT_CARD":
          return "Vendor";
        case "CHECK":
        case "E-CHECK":
          return "Pay to";
        default:
          return "Vendor";
      }
    },
    getDescriptionLabel(method) {
      switch (method) {
        case "CREDIT_CARD":
          return "Concept";
        case "CHECK":
        case "E-CHECK":
          return "Memo";
        default:
          return "Concept";
      }
    },
    getDangerButtonText(method) { 
      switch (method) { 
        case "CREDIT_CARD":
          return "Reject";      
        case "CHECK":
          return "Reject";
        case "E-CHECK":
          return "Uncollect";
        default:
          return "Reject";
      }
    },
  },
};
</script>
<style scoped>
.memo-class {
  height: 200px;
  width: 100%;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding: 0.438rem 1rem;
  overflow: auto;
  white-space: pre-wrap;
}
</style>
>
