export default [
  {
    key: "check_details",
    label: "Check Details",
    class: "text-left",
    visible: true,
    thClass: "text-left",
    tdClass: "text-left col-4",
  },
  {
    key: "module",
    label: "Department",
    class: "text-center",
    visible: true,
  },
  {
    key: "type",
    label: "Type",
    class: "text-center",
    visible: true,
  },
  {
    key: "type_shipping",
    label: "Type shipping",
    class: "text-center",
    visible: false,
  },
  {
    key: "amount",
    label: "Amount",
    class: "text-center",
    visible: true,
  },
  {
    key: "to_correspondence",
    label: "Entered at",
    class: "text-center",
    visible: true,
  },
  {
    key: "to_courier",
    label: "Sent to courier at",
    class: "text-center",
    visible: false,
  },
  {
    key: "cancelled_by",
    label: "Cancelled at",
    class: "text-center ",
    visible: false,
  },
  {
    key: "actions",
    label: "Actions",
    class: "text-center",
    tdClass: "py-2",
    visible: true,
  },
];
