<template>
  <div class="px-2">
    <!--
      Poner input width solo en caso necesarios:
      :input-width="60"

      Colocar paginate si se desea mostrar la paginación en la parte superior
      paginate-left
    -->
    <filter-slot
      :filter="[]"
      :total-rows="FiltersForSlot.totalRows"
      :filter-principal="{}"
      :paginate="FiltersForSlot.paginate"
      :start-page="FiltersForSlot.startPage"
      :to-page="FiltersForSlot.toPage"
      :noVisiblePrincipalFilter="true"
      @reload="$refs['checksTable'].refresh()"
      class="filter-slot-new-customization"
    >
      <b-table
        ref="checksTable"
        slot="table"
        class="blue-scrollbar position-relative table-new-customization"
        :items="getChecks"
        :fields="visibleFields"
        :busy.sync="isBusy"
        primary-key="id"
        responsive="md"
        sticky-header="50vh"
        show-empty
        no-provider-filtering
        :per-page="FiltersForSlot.paginate.perPage"
        :current-page="FiltersForSlot.paginate.currentPage"
        table-class="text-nowrap"
      >
        <template #cell(module)="{ item }">
          <div class="d-flex justify-content-center align-items-center">
            <img
              v-b-tooltip.hover.left="item.module_name"
              :src="`/assets/${item.icon_module}`"
              alt="Logo"
              height="30"
              width="30"
            />
          </div>
        </template>
        <template #cell(type)="{ item }">
          <b-badge
            class="px-1"
            :class="item.type == 'CLIENT' ? 'clickable' : ''"
            :variant="item.type == 'CLIENT' ? 'primary' : 'info'"
            @click="
              item.type == 'CLIENT' ? openModalClientExpense(item) : false
            "
          >
            {{ item.type }}
            {{ item.total_clients > 0 ? `(${item.total_clients})` : null }}
          </b-badge>
        </template>
        <template #cell(type_shipping)="{ value }">
          <b-badge
            :variant="value == 'regular' ? 'info' : 'warning'"
            class="text-uppercase px-1"
            >{{ value }}</b-badge
          >
        </template>
        <template #cell(check_number)="{ item }">
          <span>
            {{ item.check_number }}
          </span>
        </template>
        <template #cell(check)="{ item }">
          <a :href="item.route_pdf" target="_blank">
            <amg-icon
              class="cursor-pointer text-danger"
              icon="FilePdfIcon"
              size="18"
            />
          </a>
        </template>
        <template #cell(check_details)="{ item }">
          <b-row>
            <b-col cols="2"><strong>Check #</strong></b-col>
            <b-col>{{ item.check_number }}</b-col>
            <div class="w-100"></div>
            <b-col cols="2"><strong>Pay to</strong></b-col>
            <b-col>{{ item.vendor }}</b-col>
            <div class="w-100"></div>
            <b-col cols="2"><strong>Memo</strong></b-col>
            <b-col>{{ item.memo }}</b-col>
            <div class="w-100"></div>
            <b-col cols="2"><strong>Address</strong></b-col>
            <b-col>
              <span v-html="formatAddress(item)"></span>
            </b-col>
            <div class="w-100"></div>
            <b-col cols="2"><strong>Check</strong></b-col>
            <b-col
              ><a :href="item.route_pdf" target="_blank">
                <amg-icon
                  class="cursor-pointer text-danger"
                  icon="FilePdfIcon"
                  size="18"
                /> </a
            ></b-col>
            <div class="w-100"></div>
            <b-col cols="2" v-if="item.route_attach"
              ><strong>File</strong></b-col
            >
            <b-col v-if="item.route_attach"
              ><a :href="item.route_attach" target="_blank">
                <amg-icon
                  class="cursor-pointer text-danger"
                  icon="FilePdfIcon"
                  size="18"
                /> </a
            ></b-col>
          </b-row>
        </template>
        <template #cell(amount)="{ item }">
          <span class="text-success">
            {{ item.amount | formatMoney }}
          </span>
        </template>
        <template #cell(actions)="{ item }">
          <feather-icon
            class="cursor-pointer mr-1 text-success"
            icon="SendIcon"
            v-b-tooltip.hover.bottom="'SEND TO COURIER'"
            size="18"
            @click="trackingCheckModal(item, false)"
          />
          <feather-icon
            class="cursor-pointer mr-1 text-danger"
            icon="XIcon"
            v-b-tooltip.hover.bottom="'CANCEL SENDING'"
            size="18"
            @click="observationModal(item)"
          />
        </template>
        <template #cell(to_correspondence)="{ item }">
          <span class="d-block">{{ item.sent_to_correspondence_by }}</span>
          <span class="d-block"
            >{{ item.sent_to_correspondence_at | myGlobalDay }}
          </span>
        </template>
        <template #cell(to_courier)="{ item }">
          <span class="d-block">{{ item.sent_to_courier_by }}</span>
          <span class="d-block"
            >{{ item.sent_to_courier_at | myGlobalDay }}
          </span>
          <feather-icon
            icon="InfoIcon"
            v-b-tooltip.hover.top="'See details'"
            variant="info"
            class="text-info cursor-pointer"
            style="margin-left: 3px"
            @click="trackingCheckModal(item, true)"
          />
        </template>
        <template #cell(cancelled_by)="{ item }">
          <span class="d-block">{{ item.cancelled_by }}</span>
          <span class="d-block">{{ item.cancelled_at | myGlobalDay }}</span>
          <feather-icon
            v-if="item.observation"
            icon="InfoIcon"
            v-b-tooltip.hover.top="item.observation"
            variant="info"
            class="text-info"
            style="margin-left: 3px"
          />
        </template>
      </b-table>
    </filter-slot>

    <ObservationsModal
      v-if="showObservationsModal"
      :expenseData="selectedItem"
      @close="showObservationsModal = false"
      @save="saveRejectedCheck"
    />
    <tracking-check
      v-if="showTrackingCheckModal"
      :dataRegister="selectedItem"
      :onlyRead="onlyRead"
      @refresh="refreshTable()"
      @close="showTrackingCheckModal = false"
    />
    <client-expense-request
      :expenseDepartment="expenseDepartment"
      v-if="expenseDepartment"
      @close="expenseDepartment = null"
      :notClickeable="true"
    />
  </div>
</template>
<script>
import Fields from "@/views/correspondence/views/checks/data/checks.fields.js";
import CorrespondenceServices from "@/views/correspondence/services/correspondence.service.js";
import ObservationsModal from "@/views/ceo/dashboard/modals/expenses-request/ObservationsModal.vue";
import TrackingCheck from "@/views/correspondence/views/checks/view/TrackingCheck.vue";
import { mapGetters, mapActions } from "vuex";
import ClientExpenseRequest from "@/views/ceo/dashboard/modals/expenses-request/ClientExpenseRequest.vue";

import store from "@/store";

export default {
  name: "CorrespondenceChecksGrid",
  components: {
    ObservationsModal,
    TrackingCheck,
    ClientExpenseRequest,
  },
  data() {
    return {
      fields: Fields,
      FiltersForSlot: {
        totalRows: 0,
        paginate: {
          perPage: 10,
          currentPage: 1,
        },
        startPage: 1,
        toPage: 1,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Search by check number, vendor or concept...",
          model: "",
        },
      },
      isBusy: false,
      checks: [],
      showObservationsModal: false,
      newCheckNumber: null,
      showTrackingCheckModal: false,
      selectedItem: null,
      expenseDepartment: null,
      onlyRead: false,
    };
  },
  created() {
    this.filterColumn("to_courier", this.isSentTab);
    this.filterColumn("type_shipping", this.isSentTab);
    this.filterColumn("cancelled_by", this.isCancelledTab);
    this.filterColumn("actions", this.isNewTab);
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_COUNTER_CHECK: "CheckStore/G_COUNTER_CHECK",
    }),
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    isNewTab() {
      return this.$route.name == this.$route.matched[1].meta.routeNew;
    },
    isSentTab() {
      return this.$route.name == this.$route.matched[1].meta.routeSent;
    },
    isCancelledTab() {
      return this.$route.name == this.$route.matched[1].meta.routeCancelled;
    },
    tab() {
      return this.$route.meta.tabSelected;
    },
    countNew() {
      if (Array.isArray(this.G_COUNTER_CHECK)) {
        const item = this.G_COUNTER_CHECK.find(
          (item) => item.name_status === "SEND TO CORRESPONDENCE"
        );
        return item ? item.count : 0;
      }
      return 0;
    },
  },
  methods: {
    ...mapActions({
      A_COUNTER_CHECK: "CheckStore/A_COUNTER_CHECK",
    }),
    async getChecks() {
      const params = {
        tab: this.tab,
        page: this.FiltersForSlot.paginate.currentPage,
        per_page: this.FiltersForSlot.paginate.perPage,
        search: this.FiltersForSlot.filterPrincipal.model,
      };
      try {
        this.addPreloader();
        const { data } = await CorrespondenceServices.getCorrespondenceChecks(
          params
        );
        this.FiltersForSlot.startPage = data.from;
        this.FiltersForSlot.paginate.currentPage = data.current_page;
        this.FiltersForSlot.paginate.perPage = data.per_page;
        this.FiltersForSlot.totalRows = data.total;
        this.FiltersForSlot.toPage = data.to;
        this.checks = data.data.map((item) => {
          return {
            ...item,
            edit_check_number: false,
          };
        });
        return this.checks;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async changeStatus(item, action) {
      const message =
        action == "send" ? "send to courier" : "cancel the sending";
      const confirm = await this.showConfirmSwal(
        `Are you sure you want to ${message}?`
      );
      if (!confirm.isConfirmed) return;

      const params = {
        check_id: item.check_id,
        action: action,
        user_id: this.currentUser.user_id,
      };
      try {
        this.addPreloader();
        const { data } = await CorrespondenceServices.changeCheckStatus(params);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Successfully process"
          );
          await this.refreshTable();
        } else {
          this.showToast(
            "error",
            "top-right",
            "Error",
            "XCircleIcon",
            "Error process"
          );
        }
        // this.A_COUNTER_CHECK();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async saveRejectedCheck(obs) {
      const params = {
        check_id: this.selectedItem.check_id,
        observation: obs,
        user_id: this.currentUser.user_id,
        department_expense_id: this.selectedItem.department_expense_id,
        user_name: this.currentUser.fullName,
        check_number: this.selectedItem.check_number,
        type: this.selectedItem.type,
        reason: "cancelled",
      };
      try {
        this.addPreloader();
        const { data } = await CorrespondenceServices.annulCheck(params);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Successfully process"
          );
          this.showObservationsModal = false;
          await this.refreshTable();
        } else {
          this.showToast(
            "error",
            "top-right",
            "Error",
            "XCircleIcon",
            "Error process"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async changeCheckNumber(item) {
      const validation = await this.$refs.observer.validate();
      if (!validation) return;

      const confirm = await this.showConfirmSwal(
        "Are you sure you want to change the check number?"
      );
      if (!confirm.isConfirmed) return;

      const params = {
        check_id: item.check_id,
        user_id: this.currentUser.user_id,
        new_check_number: this.newCheckNumber,
        old_check_number: item.check_number,
        changed_by: this.currentUser.fullName,
        requested_by: item.requested_by,
        amount: item.amount,
        memo: item.memo,
        vendor: item.vendor,
      };
      try {
        this.addPreloader();
        const { data } = await CorrespondenceServices.changeCheckNumber(params);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Successfully process"
          );
          item.old_check_number = item.check_number;
          item.check_number = this.newCheckNumber;
          item.edit_check_number = false;
          this.newCheckNumber = null;
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Oop!",
            "AlertOctagonIcon",
            "Failed process"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    trackingCheckModal(item, onlyRead) {
      this.onlyRead = onlyRead;
      this.showTrackingCheckModal = true;
      this.selectedItem = item;
    },
    observationModal(item) {
      this.showObservationsModal = true;
      this.selectedItem = item;
      this.selectedItem.method = "CHECK";
      this.selectedItem.concept = item.memo;
      this.selectedItem.street_address = item.address;
      this.selectedItem.module_name = item.module_name;
      this.selectedItem.icons = item.icon_module;
    },
    filterColumn(key, bool) {
      let field = this.fields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },
    async updateCounterStore() {
      await store.dispatch("CheckStore/A_COUNTER_CHECK");
      const payload = {
        routeName: "correspondence-checks",
        tag: this.countNew,
      };
      store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
    },
    refreshTable() {
      this.$refs["checksTable"].refresh();
    },
    openModalClientExpense(item) {
      this.expenseDepartment = {
        ...item,
        concept: item.memo,
        id: item.department_expense_id,
        method: "CHECK",
      };
    },
  },
  watch: {
    checks() {
      this.updateCounterStore();
    },
  },
};
</script>
<style>
.type--expense--correspondence span.total--clients--correspondence {
  text-decoration: underline;
  font-weight: 900;
  cursor: pointer;
  font-size: 14px;
  padding: 0.8rem;
}
</style>
