var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"clientExpenseRequest",attrs:{"modal-class":"modal-primary","title-class":"h3 text-white","title":"Clients","size":"lg","hide-footer":""},on:{"hidden":function($event){return _vm.closeModal()}}},[_c('b-row',{staticClass:"client-info"},[_c('b-col',{staticClass:"p-0",attrs:{"cols":"6"}},[_c('span',{staticClass:"mr-1 mb-1"},[_vm._v(_vm._s(["CHECK", "E-CHECK"].includes(_vm.expenseDepartment.method) ? "Pay to" : "Vendor")+" ")]),_c('div',{staticClass:"mr-1",class:_vm.isDarkSkin ? 'border-custom' : 'border-custom-light'},[_c('p',[_vm._v(" "+_vm._s(_vm.expenseDepartment.vendor)+" ")])])]),_c('b-col',{staticClass:"p-0",attrs:{"cols":"6"}},[_c('span',{staticClass:"ml-1 mb-1"},[_vm._v(" "+_vm._s(["CHECK", "E-CHECK"].includes(_vm.expenseDepartment.method) ? "Memo" : "Concept")+" ")]),_c('div',{staticClass:"ml-1",class:_vm.isDarkSkin ? 'border-custom' : 'border-custom-light'},[_c('p',[_vm._v(" "+_vm._s(_vm.expenseDepartment.concept)+" ")])])])],1),_c('b-table',{ref:"detailsExpenses",staticClass:"position-relative table-new-customization",attrs:{"id":"detailsExpenses","no-border-collapse":"","fields":_vm.fields,"show-empty":"","no-provider-filtering":"","sticky-header":"50vh","primary-key":"id","responsive":"sm","items":_vm.itemsClientsExpense,"busy":_vm.isBusy},on:{"update:busy":function($event){_vm.isBusy=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(client)",fn:function(ref){
var item = ref.item;
return [_c('div',[(!_vm.notClickeable && item.type_client == 'CLIENT')?_c('router-link',{attrs:{"to":_vm.isCeo
              ? _vm.routerDashboardRedirectioner(
                  item.program_id,
                  item.client_account_id
                )
              : {
                  name: _vm.getRouterName(item)[0],
                  params: _vm.getRouterName(item)[1],
                },"target":"_blank"}},[_vm._v(_vm._s(item.client)+" ")]):(!_vm.notClickeable && item.type_client == 'LEAD')?_c('router-link',{attrs:{"to":_vm.isCeo
              ? _vm.routerDashboardLeadRedirectioner(2, item.lead_id)
              : {
                  name: _vm.getRouterName(item)[0],
                  params: _vm.getRouterName(item)[1],
                },"target":"_blank"}},[_vm._v(_vm._s(item.client)+" ")]):_c('span',[_vm._v(_vm._s(item.client))]),(item.type_client == 'CLIENT')?_c('div',[_c('status-account',{attrs:{"account":Object.assign({}, item, {status: item.status})}}),_c('span',[_vm._v(_vm._s(item.account))])],1):_c('div',[_c('span',{staticClass:"d-block"},[_vm._v("ID: "+_vm._s(item.lead_id))]),_c('span',{staticClass:"d-block"},[_vm._v("Start date: "+_vm._s(_vm._f("myGlobal")(item.lead_start_date)))])])],1)]}},{key:"cell(type_client)",fn:function(ref){
                var item = ref.item;
return [_c('b-badge',{attrs:{"variant":item.type_client == 'CLIENT' ? 'primary' : 'warning'}},[_vm._v(_vm._s(item.type_client))])]}},{key:"cell(amount)",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatMoney")(item.amount))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }